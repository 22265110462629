// const BASE_URI = 'ws://localhost:3001';
const BASE_URI = 'wss://api.play.tokenduels.gg';

export function setupWebSocket({ onUpdateBalance, onNewToaster }) {


    const token = localStorage.getItem('discord_token');

    if (!token) {
        console.error('No token found in localStorage');
        return null;
    }

    const ws = new WebSocket(`${BASE_URI}/?token=${token}`);


    ws.onopen = () => {
        console.log('WebSocket connection established');
    };

    ws.onmessage = (message) => {
        const parsedMessage = JSON.parse(message.data);
        switch (parsedMessage.type) {
            case 'UPDATE_BALANCE':
                if (onUpdateBalance) {
                    console.log("new bal");
                    onUpdateBalance(parsedMessage.newBalance);
                }
                break;
            case 'NEW_TOASTER':
                if (onNewToaster) {
                    onNewToaster(parsedMessage);
                }
                break;
            default:
                console.log('Unknown message type:', parsedMessage.type);
        }
    };

    ws.onclose = (event) => {
        console.log('WebSocket connection closed:', event);
    };

    ws.onerror = (error) => {
        console.error('WebSocket error:', error);
    };

    return ws;
}
