import React, { useEffect } from 'react';
import '../assets/styles/GameView.css';


// const BASE_URI = 'http://localhost:3001';
const BASE_URI = 'https://api.play.tokenduels.gg';

function AuthView() {
    const handleDiscordLogin = () => {
        window.location.href = `${BASE_URI}/auth/discord`;
    };

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const discordToken = urlParams.get('discord_token');
        const discordId = urlParams.get('discord_id');
        const username = urlParams.get('username');
        const profilePicture = urlParams.get('profile_picture');
        const flowAddress = urlParams.get('flow_address');

        if (discordToken && discordId && username && profilePicture && flowAddress) {
            localStorage.setItem('discord_token', discordToken);
            localStorage.setItem('discord_id', discordId);
            localStorage.setItem('username', username);
            localStorage.setItem('profile_picture', profilePicture);
            localStorage.setItem('flow_address', flowAddress);

            window.history.replaceState({}, document.title, window.location.pathname);

            window.location.reload();
        }
    }, []);

    return (
        <div className='auth-view-container'>
            <button className='blue-button bet-blue' style={{ padding: '1vh 2vh' }} onClick={handleDiscordLogin}>
                LOGIN WITH DISCORD
            </button>
        </div>
    );
}


export default AuthView;
