import React, { useState, useEffect } from 'react';
import './assets/styles/App.css';
import GameView from './components/GameView';
import LoadingView from './components/LoadingView';
import AuthView from './components/AuthView';
import PasswordAuthView from './components/PasswordAuthView';
import axios from 'axios'

const blueHighlight = require('./assets/imgs/BlueHighlight.png');

// const BASE_URI = 'http://localhost:3001';
const BASE_URI = 'https://api.play.tokenduels.gg';

function App() {
    const [pageView, setPageView] = useState('loading');
    const [balance, setBalance] = useState(null); // Initialize as null to indicate loading state
    const [discordToken, setDiscordToken] = useState()
    const [passwordToken, setPasswordToken] = useState()

    const updateBalance = (newBalance) => {
        setTimeout(() => {
            setBalance(newBalance);
        }, 3000)
    };

    const fetchBalance = async (token) => {
        try {
            const response = await axios.post(`${BASE_URI}/api/get-balance`, { token });
            if (response.status === 200) {
                setBalance(response.data.balance);
                localStorage.setItem('flip_hash', response.data.houseFlip)
            } else {
                console.error('Failed to fetch balance:', response.data.message);
            }
        } catch (error) {
            console.error('Error fetching balance:', error);
        }
    };

    const validateTokenOnLoad = async () => {
        const token = localStorage.getItem('discord_token');

        if (!token) {
            console.log('No token found');
            return;
        }

        try {
            const response = await axios.post(`${BASE_URI}/api/validate-token`, { token });

            const data = response.data;
            console.log(data)
            if (response.status !== 200 || data.message !== 'Token is valid') {
                console.log('Token is invalid or expired');
                localStorage.removeItem('discord_token');
                window.location.reload();
            } else {
                console.log('Token is valid');
            }
        } catch (error) {
            console.error('Error validating token:', error);
            localStorage.removeItem('discord_token');
            window.location.reload();
        }
    };

    const handleAuthSuccess = () => {
        setPageView('gameview');
        const discord_token = localStorage.getItem('discord_token');
        const password_token = localStorage.getItem('password_token');
        setDiscordToken(discord_token)
        setPasswordToken(password_token)

    };

    useEffect(() => {
        const discord_token = localStorage.getItem('discord_token');
        const password_token = localStorage.getItem('password_token');

        if (discord_token && password_token) {
            validateTokenOnLoad()
            fetchBalance(discord_token);
            setPageView('gameview');
        } else if (!discord_token) {
            setPageView('auth');
        } else if (!password_token) {
            setPageView('passwordview');
        }
    }, []);

    useEffect(() => {
        fetchBalance(discordToken)
    }, [discordToken])

    if (pageView === 'loading') {
        return <LoadingView />;
    }


    return (
        <div className="App">
            <img src={blueHighlight} className='blue-highlight' alt="highlight" />
            {pageView === 'loading' && <LoadingView />}
            {pageView === 'auth' && <AuthView onAuthSuccess={handleAuthSuccess} />}
            {pageView === 'passwordview' && <PasswordAuthView onAuthSuccess={handleAuthSuccess} />}
            {pageView === 'gameview' && <GameView balance={balance} updateBalance={updateBalance} />}
        </div>
    );
}

export default App;
