import React from 'react'
import '../assets/styles/GameView.css'
const SpinLogo = require('../assets/imgs/DuelFlipLinearSpin.gif')

function LoadingView() {
  return (
    <div className='loading-view-container'>
        <img src={SpinLogo}/>
        <h2>LOADING</h2>
    </div>
  )
}

export default LoadingView