import React, { useState, useEffect } from 'react';
import { setupWebSocket } from '../../services/WebSocket';

const Gold = require('../../assets/imgs/DuelFlipFlatGoldArtboard 1.png');
const Grey = require('../../assets/imgs/DuelFlipFlatGreyArtboard 1.png');

function MainToasters() {
    const [toasters, setToasters] = useState([]);

    useEffect(() => {
        const ws = setupWebSocket({
            onNewToaster: ({ content, key }) => {
                setToasters((prevToasters) => {
                    if (prevToasters.some(toaster => toaster.key === key)) {
                        console.log(`Duplicate toaster found: ${content} with key: ${key}`);
                        return prevToasters;
                    }
                    console.log(`Adding toaster: ${content} with key: ${key}`);
                    return [{ content, key }, ...prevToasters]; 
                });
            }
        });

        return () => {
            if (ws) ws.close();
        };
    }, []);

    return (
        <div className='main-toasters-container'>
            {toasters.map((toaster) => (
                <Toaster key={toaster.key} content={toaster.content} />
            ))}
            <div className='toaster-blur' />
        </div>
    );
}

const Toaster = ({ content }) => {
    const isDoubled = typeof content === 'string' && content.includes('DOUBLED');
    const imageSrc = isDoubled ? Gold : Grey;
    
    return (
        <div className='toaster-container'>
            <img src={imageSrc} className='toaster-image' alt="Toaster"/>
            <div className='toaster-name-container'>
                <h1 style={{textTransform: 'uppercase'}}>{content.split(' ')[0]}</h1>
                <h2 style={{textTransform: 'uppercase'}}>{content}</h2>
            </div>
        </div>
    );
}

export default MainToasters;
