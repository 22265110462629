import React, { useState } from 'react';
import '../../assets/styles/Modals.css';

import axios from 'axios'
const Gem = require('../../assets/imgs/TDGem.png');

// const BASE_URI = 'http://localhost:3001';
const BASE_URI = 'https://api.play.tokenduels.gg';

function ProfileModal({ setShowModal, updateBalance, balance }) {
    const profilePicture = localStorage.getItem('profile_picture');
    const username = localStorage.getItem('username');
    const discordId = localStorage.getItem('discord_id');
    const flowAccount = localStorage.getItem('flow_address');
    const token = localStorage.getItem('discord_token');

    const [depositStatus, setDepositStatus] = useState('');
    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [withdrawFlowAddress, setWithdrawFlowAddress] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);

    const handleRefreshDeposit = async () => {
        setIsProcessing(true);
        setDepositStatus('Approving transaction...');

        try {
            const response = await axios.post(`${BASE_URI}/api/check-deposit`, {
                account: flowAccount,
                token: token,
            });

            if (response.status === 200) {
                setDepositStatus('Checking Deposit...');
                const data = response.data;
                setDepositStatus(`Deposit refreshed successfully. Balance: $${data.newBalance}`);
                updateBalance(data.newBalance);
            } else {
                setDepositStatus('Failed to refresh deposit.');
            }
        } catch (error) {
            console.error('Error refreshing deposit:', error);
            setDepositStatus('Error occurred while refreshing deposit.');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleWithdraw = async () => {
        if (!withdrawAmount || isNaN(withdrawAmount) || withdrawAmount <= 0) {
            setDepositStatus('Please enter a valid withdrawal amount.');
            return;
        }

        if (!withdrawFlowAddress) {
            setDepositStatus('Please enter a valid Flow address.');
            return;
        }

        setIsProcessing(true);
        setDepositStatus('Processing withdrawal...');

        try {
            const response = await axios.post(`${BASE_URI}/api/withdraw`, {
                token: token,
                amount: withdrawAmount,
                flowAddress: withdrawFlowAddress,
            });

            if (response.status === 200) {
                const data = response.data;
                setDepositStatus(`Withdrawal successful. New Balance: $${data.newBalance}`);
                updateBalance(data.newBalance);
            } else {
                const errorData = response.data;
                setDepositStatus(`Withdrawal failed: ${errorData.message}`);
            }
        } catch (error) {
            console.error('Error processing withdrawal:', error);
            setDepositStatus('Error occurred while processing withdrawal.');
        } finally {
            setIsProcessing(false);
            setWithdrawAmount('');
            setWithdrawFlowAddress('');
        }
    };


    return (
        <div className='modal-wrapper'>
            <div className='modal-container'>
                <button className='blue-button close-modal-button' onClick={() => setShowModal('PROFILE_MODAL')}>
                    CLOSE
                </button>
                <div className='profile-section-container'>
                    <div className='profile-section-container-horizontal'>
                        <div className='profile-pfp'>
                            {profilePicture ? (
                                <img src={`https://cdn.discordapp.com/avatars/${discordId}/${profilePicture}`} className='profile-pfp-img' alt='Profile' />
                            ) : (
                                <div className='profile-pfp-placeholder'>No Image</div>
                            )}
                        </div>
                        <div className='profile-name'>
                            <h2 style={{ textTransform: 'uppercase' }}>{username ? username : 'Username not found'}</h2>
                            <h3>{discordId ? `ID: ${discordId}` : 'Discord ID not found'}</h3>
                            <h3>{flowAccount ? `Flow Account: ${flowAccount}` : 'Flow Account not found'}</h3>

                        </div>
                    </div>
                    <div className='profile-section-container-horizontal'>
                        <div className='profile-actions'>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: '1vw' }}>

                                <div className='basic-input' style={{ width: 'fit-content', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', padding: '0 2vw 0 1.2vw', gap: '1vw' }}>
                                    <img src={Gem} style={{ width: '3.5vh' }} />

                                    {balance.toFixed(2)}
                                </div>
                                <button
                                    className='blue-button button-padding'
                                    onClick={handleRefreshDeposit}
                                    disabled={isProcessing}
                                    style={{ opacity: isProcessing ? 0.5 : 1 }}
                                >
                                    REFRESH DEPO
                                </button>
                            </div>

                            <div className='depo-withdraw-buttons'>
                                <input
                                    className='basic-input'
                                    type='number'
                                    placeholder='Amount'
                                    value={withdrawAmount}
                                    onChange={(e) => setWithdrawAmount(e.target.value <= balance ? e.target.value : balance.toFixed(2))}
                                />
                                <input
                                    className='basic-input'
                                    type='text'
                                    placeholder='Flow Address'
                                    value={withdrawFlowAddress}
                                    onChange={(e) => setWithdrawFlowAddress(e.target.value)}
                                />
                                <button
                                    className='blue-button button-padding'
                                    onClick={handleWithdraw}
                                    disabled={isProcessing}
                                    style={{ opacity: isProcessing ? 0.5 : 1 }}
                                >
                                    WITHDRAW
                                </button>
                            </div>
                            {depositStatus && <p>{depositStatus}</p>}
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ProfileModal;
