import React from 'react'
import HouseFlip from '../modules/HouseFlip'
import '../../assets/styles/CoinflipView.css'

function CoinflipView({ balance, updateBalance, sendUserToaster }) {
  return (
    <div className='coinflip-view-container'>
      <h1>TOKEN DUELS</h1>
      <HouseFlip balance={balance} updateBalance={updateBalance} sendUserToaster={sendUserToaster}/>
    </div>
  )
}



export default CoinflipView