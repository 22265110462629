import React from 'react';
import '../../assets/styles/GameView.css';

const Logo = require('../../assets/imgs/DuelFlipFlatBlueArtboard 1.png');
const gCoin = require('../../assets/imgs/DuelFlipFlatGreyArtboard 1.png');
const Gem = require('../../assets/imgs/TDGem.png');

function TopBar({ balance, setShowModal, setPage }) {
    let userName = localStorage.getItem('username')
    let pfpLink = localStorage.getItem('profile_picture')
    let discordId = localStorage.getItem('discord_id')

    return (
        <div className='top-bar-container'>
            <div className='top-bar-segment segment-left'>
                
                <button className='top-bar-button-tp' onClick={() => setPage('provablyFair')}>
                    [PROVABLY FAIR]
                </button>
            </div>
            <div className='top-bar-segment segment-mid'>
                <button className='top-bar-button coinflip-button' onClick={() => setPage('gameView')}>
                    <img src={Logo} />
                    COINFLIP
                </button>
                <button className='top-bar-button jackpot-button' style={{ pointerEvents: 'none' }}>
                    <div className='blur' />
                    <img src={Gem} />
                    JACKPOT
                </button>
            </div>
            <div className='top-bar-segment segment-right'>
                <div className='top-bar-button'>
                    <img src={Gem} />
                    {balance !== null ? balance.toFixed(2) : '0'}

                </div>
                <button className='top-bar-button' onClick={() => setShowModal('PROFILE_MODAL')}  style={{textTransform: 'uppercase'}}>
                    <img src={pfpLink? `https://cdn.discordapp.com/avatars/${discordId}/${pfpLink}` : gCoin} />
                    {
                        userName? userName :
                        'ANON USER'
                    
                    }
                </button>
            </div>
        </div>
    );
}

export default TopBar;
