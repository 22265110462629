import React, { useState } from 'react';
import axios from 'axios';

// const BASE_URI = 'http://localhost:3001';
const BASE_URI = 'https://api.play.tokenduels.gg';

function ProvablyFair() {
    const [seed, setSeed] = useState('');
    const [key, setKey] = useState('');
    const [hash, setHash] = useState(null);
    const [number, setNumber] = useState(null); // To store the number as well

    const handleCrossMatch = async () => {
        const token = localStorage.getItem('discord_token'); // Assuming you store the token in local storage

        if (!token || !seed || !key) {
            alert('Please fill in all fields and make sure you are logged in.');
            return;
        }

        try {
            const response = await axios.post(`${BASE_URI}/api/prove-houseflip`, {
                token,
                seed,
                key,
            });

            if (response.status === 200) {
                const { flipHash, number } = response.data;
                setHash(flipHash);
                setNumber(number); // Set the number to state
            } else {
                console.error('Failed to prove houseflip:', response.data.message);
            }
        } catch (error) {
            console.error('Error proving houseflip:', error);
        }
    };

    return (
        <div className='provably-fair-page-container'>
            <div className='provably-fair-container'>
                <div className='provably-input-container'>
                    <h1>
                        WHAT IS PROVABLY FAIR
                    </h1>
                    <h2>
                        Provably Fair is a system that enables players to confirm the legitimacy of a gaming site and ensures that game outcomes are not manipulated. It uses cryptography and external inputs to produce random values. After the game concludes, players can verify that the result was genuinely derived from the initial seed and inputs, providing proof that the game was fair. For our games, users will be given a hash before the game has ended, where they will recieve a seed and key to cross reference and recieve the same hash. The number represents the flip outcome, if its <span style={{ color: '#DC4F63' }}>even,</span> the outcome is <span style={{ color: '#DC4F63' }}>red,</span> if its <span style={{ color: '#5097F8' }}>odd,</span> the outcome is <span style={{ color: '#5097F8' }}>blue.</span>
                    </h2>
                </div>
                <div className='provably-input-container'>
                    <h1>SEED</h1>
                    <h2>
                        The Server Seed is a randomly generated hexadecimal string created by the server before each PvE game. This seed is securely stored in our database, and its SHA256 hash is sent to the client. Since hashing always produces the same result and cannot be reversed, the client remains unaware of the actual outcome but can confirm that the server isn't altering the seed during the game.
                    </h2>
                    <input
                        className='basic-input'
                        value={seed}
                        onChange={(e) => setSeed(e.target.value)}
                        placeholder='SEED'
                    />
                </div>
                <div className='provably-input-container'>
                    <h1>KEY</h1>
                    <h2>
                        The RandomKeyGenerator contract generates a random key using blockchain data. It combines the current block's height and timestamp, converting the timestamp to a 64-bit unsigned integer. The random key is produced by performing a bitwise XOR operation between the block height and the timestamp, resulting in a unique, pseudo-random 64-bit key. This key is then returned for use. This contract can be found on flows mainnet at: <span style={{ color: '#5097F8' }}>0x73895b75543f996d
                        </span>
                    </h2>
                    <input
                        className='basic-input'
                        value={key}
                        onChange={(e) => setKey(e.target.value)}
                        placeholder='KEY'
                    />
                </div>
                <button
                    className='blue-button bet-blue button-padding'
                    onClick={handleCrossMatch}
                >
                    CROSSMATCH
                </button>
                <div className='basic-input pf-hash'>
                    {hash ? (
                        <div>
                            <h1>{hash}</h1>
                        </div>
                    ) : (
                        <h1>HASH OUTPUT</h1>
                    )}

                </div>

                <div className='basic-input pf-hash'>
                    {hash ? (
                        <div>
                            <h1>Number Output: {number}</h1>
                        </div>
                    ) : (
                        <h1>NUMBER OUTPUT</h1>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ProvablyFair;
