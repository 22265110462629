import React from 'react'

function ChatRoom() {
  return (
    <div className=''>
        
    </div>
  )
}

export default ChatRoom