import React, { useEffect } from 'react'
const yellowCoin = require('../../assets/imgs/DuelFlipFlatGoldArtboard 1.png')
const greyCoin = require('../../assets/imgs/DuelFlipFlatGreyArtboard 1.png')

function UserToast({ win, wager, setShowUserToaster }) {
    let source = win ? yellowCoin : greyCoin

    console.log(win)
    useEffect(() => {
        setTimeout(() => {
            setShowUserToaster(false)
        }, 5000)
    }, [])

    return (
        <div className='user-toast-container'>
            <img src={source} />
            <div className='user-toast-description'>
                {
                    win ?
                        <>
                            <h1>
                                WINNER!
                            </h1>
                            <h2>
                                YOU WON {wager} {Number(wager) == 1 ? 'GEM!' : 'GEMS!'}
                            </h2>
                        </>

                        :
                        <>
                            <h1>
                                LOSER!
                            </h1>
                            <h2>
                                YOU LOST {wager} GEM!
                            </h2>
                        </>
                }

            </div>
        </div>
    )
}

export default UserToast