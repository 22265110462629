import React, { useState, useEffect } from 'react';
import { setupWebSocket } from '../../services/WebSocket';
import axios from 'axios';

const SpinLogo = require('../../assets/imgs/DuelFlipLinearSpin.gif');
const BB = require('../../assets/imgs/DuelFlipBB.gif');
const RB = require('../../assets/imgs/DuelFlipRB.gif');
const RR = require('../../assets/imgs/DuelFlipRR.gif');
const BR = require('../../assets/imgs/DuelFlipBR.gif');
const GEM = require('../../assets/imgs/TDGem.png')

// const BASE_URI = 'http://localhost:3001';
const BASE_URI = 'https://api.play.tokenduels.gg';

function HouseFlip({ balance, updateBalance, sendUserToaster }) {
    const [videoSource, setVideoSource] = useState(SpinLogo);
    const [flipping, setFlipping] = useState(false);
    const [selectedColor, setSelectedColor] = useState(null);
    const [waiting, setWaiting] = useState(false)
    const [wager, setWager] = useState();
    const [currentHash, setCurrentHash] = useState();
    const [previousKey, setPreviousKey] = useState(null);
    const [previousSeed, setPreviousSeed] = useState(null);

    useEffect(() => {
        const ws = setupWebSocket({
            onUpdateBalance: (newBalance) => {
                updateBalance(newBalance);
                console.log('newBal');
            },
            onNewToaster: (content) => {
                console.log("newToaster");
            }
        });

        return () => {
            if (ws) {
                ws.close();
            }
        };
    }, [updateBalance]);

    useEffect(() => {
        requestAndSaveFlipHash();
    }, []);

    const handleGetRandomNumber = async () => {
        if (!selectedColor) {
            alert('Please select red or blue.');
            return;
        }

        setWaiting(true)

        try {
            const token = localStorage.getItem('discord_token');

            const response = await axios.post(`${BASE_URI}/api/houseflip`, { token, wager, color: selectedColor });
            if (response.status === 200) {
                const { result, newBalance, win, seed, key, newFlipHash } = response.data;
                updateBalance(newBalance);
                setFlipping(true);
                setWaiting(false)
                const flipOutcomeIsWin = result % 2 === 0;
                let selectedGif;

                if (flipOutcomeIsWin) {
                    const blueOutcomeGifs = [BB, RB];
                    selectedGif = blueOutcomeGifs[Math.floor(Math.random() * blueOutcomeGifs.length)];
                } else {
                    const redOutcomeGifs = [RR, BR];
                    selectedGif = redOutcomeGifs[Math.floor(Math.random() * redOutcomeGifs.length)];
                }

                console.log(seed, key);
                setVideoSource(selectedGif);

                // Update previous key and seed
                setPreviousKey(key);
                setPreviousSeed(seed);

                setTimeout(() => {
                    sendUserToaster(win, wager, seed, key);

                    setFlipping(false);
                    setVideoSource(SpinLogo);
                    localStorage.setItem('flip_hash', newFlipHash);
                    setCurrentHash(localStorage.getItem('flip_hash'));
                }, 4000);
            } else {
                console.error('Failed to get flip outcome:', response.data.message);
                setFlipping(false);
            }

        } catch (error) {
            console.error("Error fetching random number:", error);
            setFlipping(false);
        }
    };

    async function requestAndSaveFlipHash() {
        try {
            const token = localStorage.getItem('discord_token');
            const existingFlipHash = localStorage.getItem('flip_hash');

            if (!token) {
                console.error('No token found in localStorage');
                return;
            }

            if (existingFlipHash) {
                console.log('Flip hash already exists in local storage:', existingFlipHash);
                return existingFlipHash;
            }

            // Send a request to the backend to generate the flip hash
            const response = await axios.post(`${BASE_URI}/api/generate-flip-hash`, { token });

            if (response.status === 200) {
                const { flipHash } = response.data;

                // Save the hash in local storage
                localStorage.setItem('flip_hash', flipHash);
                console.log('Flip hash saved to local storage:', flipHash);

                return flipHash;
            } else {
                console.error('Failed to generate flip hash:', response.data.message);
                return null;
            }
        } catch (error) {
            console.error('Error generating flip hash:', error);
            return null;
        }
    }

    const copyHashToClipboard = () => {
        navigator.clipboard.writeText(localStorage.getItem('flip_hash')).then(() => {
            console.log('Hash copied to clipboard!');
        }).catch((err) => {
            console.error('Failed to copy hash: ', err);
        });

    };

    return (
        <div className='houseflip-module-container'>
            <img src={videoSource} className={`houseflip-logo-spin ${flipping ? 'flipping' : ''}`} alt="House Flip" />
            <div className='houseflip-bet-buttons-container'>
                <div className='houseflip-bet-buttons-row'>
                    <button className={`blue-button blue-bet ${wager === 1 ? 'yellow-button' : ''} ${(waiting || flipping) && wager !== 1 ? 'grey-button' : ''} ${waiting || flipping ? 'no-pointer' : ''}`} onClick={() => { setWager(1) }}>
                        <img src={GEM} className='button-gem' />
                        1
                    </button>
                    <button className={`blue-button blue-bet ${wager === 2 ? 'yellow-button' : ''} ${(waiting || flipping) && wager !== 2 ? 'grey-button' : ''} ${waiting || flipping ? 'no-pointer' : ''}`} onClick={() => { setWager(2) }}>
                        <img src={GEM} className='button-gem' />
                        2
                    </button>
                    <button className={`blue-button blue-bet ${wager === 5 ? 'yellow-button' : ''} ${(waiting || flipping) && wager !== 5 ? 'grey-button' : ''} ${waiting || flipping ? 'no-pointer' : ''}`} onClick={() => { setWager(5) }}>
                        <img src={GEM} className='button-gem' />
                        5
                    </button>
                </div>
                <div className='houseflip-bet-buttons-row'>
                    <button className={`blue-button blue-bet ${(waiting || flipping) && selectedColor !== 'blue' && 'grey-button'} ${waiting || flipping ? 'no-pointer' : ''}`} onClick={() => { setSelectedColor('blue') }}>
                        BLUE
                    </button>
                    <button className={`red-button blue-bet ${(waiting || flipping) && selectedColor !== 'red' && 'grey-button'} ${waiting || flipping ? 'no-pointer' : ''}`} onClick={() => { setSelectedColor('red') }}>
                        RED
                    </button>
                </div>
            </div>
            {
                !flipping && wager && selectedColor && (
                    <button className={`blue-button blue-bet ${selectedColor === 'blue' ? 'blue-button' : 'red-button'} ${balance < wager && 'no-pointer'}`} onClick={handleGetRandomNumber}>
                        {waiting ? (
                            'AWAITING FLIP...'
                        ) : (

                            (balance >= wager) ? (
                                'CONFIRM BET'

                            ) : (
                                'INSUFFICIENT BALANCE'
                            )

                        )}
                    </button>
                )
            }
            <button className='top-bar-button-tp' style={{ padding: '0vw', height: 'fit-content' }} onClick={copyHashToClipboard}>
                [COPY CURRENT HASH]
            </button>

            {previousKey && previousSeed && (
                <div className='previous-results'>
                    <p>Previous Seed: {previousSeed}</p>
                    <p>Previous Key: {previousKey}</p>

                </div>
            )}
        </div>
    );
}

export default HouseFlip;
