import React, { useState, useEffect } from 'react'
import CoinflipView from './pages/CoinflipView'
import '../assets/styles/GameView.css'
import TopBar from './modules/TopBar'
import InfoModal from './modals/InfoModal'
import MainToasters from './modules/MainToasters'
import ChatRoom from './modules/ChatRoom'
import ProfileModal from './modals/ProfileModal'
import UserToast from './common/UserToast'
import ProvablyFair from './pages/ProvablyFair'

const yellowCoin = require('../assets/imgs/DuelFlipFlatGoldArtboard 1.png')
const greyCoin = require('../assets/imgs/DuelFlipFlatGreyArtboard 1.png')

function GameView({ balance, updateBalance }) {
    const [showInfoModal, setShowInfoModal] = useState(false)
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showUserToaster, setShowUserToaster] = useState(false)
    const [page, setPage] = useState('gameView')
    const [uWin, setUWin] = useState(false)
    const [uWager, setUWager] = useState(0)

    const showModal = (modal) => {
        console.log(modal, ' Now showing')
        switch (modal) {
            case 'PROFILE_MODAL':
                setShowProfileModal(!showProfileModal)
                break
            case 'INFO_MODAL':
                setShowInfoModal(!showInfoModal)
                break
            default:
                console.log('waiting modal input')
        }

    }

    const sendUserToaster = (win, wager) => {
        console.log(win, wager)
        setUWin(win)
        setUWager(wager)
        setShowUserToaster(true)
    }



    return (
        <div className='game-view-container'>
            {
                showInfoModal && (
                    <InfoModal setShowModal={showModal} />
                )
            }
            {
                showProfileModal && (
                    <ProfileModal setShowModal={showModal} updateBalance={updateBalance} balance={balance}/>
                )
            }
            {
                showUserToaster && (
                    <UserToast win={uWin} wager={uWager} setShowUserToaster={setShowUserToaster} />
                )
            }
            <div className='fixed-views'>
                <TopBar setShowModal={showModal} balance={balance} setPage={setPage} />
                {
                    page === 'gameView' && (
                        <div className='fixed-views-x'>
                            <ChatRoom />
                            <MainToasters />
                        </div>
                    )
                }

            </div>
            {
                page === 'gameView' && (
                    <CoinflipView balance={balance} updateBalance={updateBalance} sendUserToaster={sendUserToaster} />
                )
            }
            {
                page === 'provablyFair' && (
                    <ProvablyFair />
                )
            }

        </div>
    )
}

export default GameView