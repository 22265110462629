// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/VCR_OSD_MONO_1.001.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
    margin: 0;
    padding: 0;
    background-color: #11191F;
    overflow-x: none;
    font-family: 'VCR OSD Mono', sans-serif;

}

@font-face {
    font-family: 'VCR OSD Mono';
    src:
        url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    font-weight: normal;
    font-style: normal;
}

`, "",{"version":3,"sources":["webpack://./src/assets/styles/index.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,UAAU;IACV,yBAAyB;IACzB,gBAAgB;IAChB,uCAAuC;;AAE3C;;AAEA;IACI,2BAA2B;IAC3B;kEAC6D;IAC7D,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":["body {\n    margin: 0;\n    padding: 0;\n    background-color: #11191F;\n    overflow-x: none;\n    font-family: 'VCR OSD Mono', sans-serif;\n\n}\n\n@font-face {\n    font-family: 'VCR OSD Mono';\n    src:\n        url('../fonts/VCR_OSD_MONO_1.001.ttf') format('truetype');\n    font-weight: normal;\n    font-style: normal;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
