// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coinflip-view-container {
    margin-top: 7vh;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.houseflip-module-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 75vw;
    height: 75vh;
    gap: 3vh;
}

.houseflip-logo-spin {
    width: 40vh;
}

.houseflip-bet-buttons-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1vh;
}

.houseflip-bet-buttons-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 50%;
    gap: 1vh;
    justify-content: center;
}

@media (max-width: 700px) {
    .houseflip-logo-spin {
        width: 35vh;
    }
}

.button-gem {
    position: absolute;
    height: 3vh;
    top: -1.5vh;
    left: 50%;
    transform: translateX(-50%);
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/CoinflipView.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,QAAQ;AACZ;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,WAAW;IACX,QAAQ;IACR,uBAAuB;AAC3B;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,SAAS;IACT,2BAA2B;AAC/B","sourcesContent":[".coinflip-view-container {\n    margin-top: 7vh;\n    display: flex;\n    flex-direction: column;\n    width: 100vw;\n    height: 100vh;\n    align-items: center;\n    justify-content: center;\n}\n\n.houseflip-module-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 75vw;\n    height: 75vh;\n    gap: 3vh;\n}\n\n.houseflip-logo-spin {\n    width: 40vh;\n}\n\n.houseflip-bet-buttons-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    gap: 1vh;\n}\n\n.houseflip-bet-buttons-row {\n    display: flex;\n    flex-direction: row;\n    width: 100%;\n    height: 50%;\n    gap: 1vh;\n    justify-content: center;\n}\n\n@media (max-width: 700px) {\n    .houseflip-logo-spin {\n        width: 35vh;\n    }\n}\n\n.button-gem {\n    position: absolute;\n    height: 3vh;\n    top: -1.5vh;\n    left: 50%;\n    transform: translateX(-50%);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
