import React, { useState } from 'react'
import axios from 'axios'
import '../assets/styles/GameView.css'
const SpinLogo = require('../assets/imgs/DuelFlipLinearSpin.gif')


// const BASE_URI = 'http://localhost:3001';
const BASE_URI = 'https://api.play.tokenduels.gg';

function PasswordAuthView({ onAuthSuccess }) {
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        setError('')

        try {
            const response = await axios.post(`${BASE_URI}/api/password-authentication`, {
                password: password,
            })

            if (response.status === 200) {
                const token = response.data.token
                console.log('Token:', token)
                localStorage.setItem('password_token', token)
                onAuthSuccess()
            } else {
                setError('Authentication failed')
            }
        } catch (err) {
            console.error('Error:', err)
            if (err.response && err.response.data && err.response.data.message) {
                setError(err.response.data.message)
            } else {
                setError('An unexpected error occurred')
            }
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className='loading-view-container'>
            <img src={SpinLogo} className='password-auth-logo' alt='Loading' />

            <form onSubmit={handleSubmit} className='password-form'>
                <input
                    className='basic-input'
                    type='password'
                    placeholder='PASSWORD'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button className='blue-button bet-blue button-padding' type='submit' disabled={loading}>
                    {loading ? 'Submitting...' : 'SUBMIT'}
                </button>
            </form>

            {error && <p className='error-message'>{error}</p>}
        </div>
    )
}

export default PasswordAuthView
