import React from 'react'
import '../../assets/styles/Modals.css'

function InfoModal() {
  return (
    <div className='info-modal-wrapper'>
        <div className='info-modal-container'>

        </div>
    </div>
  )
}

export default InfoModal